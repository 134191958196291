/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { FlexRow, FlexCol, Heading, FauxImage, Icon } from 'sarsaparilla';

import acadiaImageSrc from './images/virtual-backgrounds/vbg-acadia.jpg';
import arapahoImageSrc from './images/virtual-backgrounds/vbg-Arapaho_and_Roosevelt.jpg';
import capitolReefImageSrc from './images/virtual-backgrounds/vbg-Capitol_Reef.jpg';
import coronadoImageSrc from './images/virtual-backgrounds/vbg-Coronado_NF.jpg';
import craterLakeImageSrc from './images/virtual-backgrounds/vbg-Crater_Lake.jpg';
import haleakalaImageSrc from './images/virtual-backgrounds/vbg-Haleakala.jpg';
import havasuImageSrc from './images/virtual-backgrounds/vbg-Havasu.jpg';
import bonoImageSrc from './images/virtual-backgrounds/vbg-sonny-bono.jpg';
import jfkImageSrc from './images/virtual-backgrounds/vbg-jfk-park.jpg';
import deschutesImageSrc from './images/virtual-backgrounds/vbg-deschutes.jpg';
import rainierImageSrc from './images/virtual-backgrounds/vbg-mt-rainier.jpg';
import staircaseImageSrc from './images/virtual-backgrounds/vbg-grand-staircase.jpg';

const photoItems = [
    {
        imagePreviewUrl: acadiaImageSrc,
        imageDownloadUrl: 'Acadia_NP',
        altText: 'Acadia National Park',
    },
    {
        imagePreviewUrl: arapahoImageSrc,
        imageDownloadUrl: 'Arapaho_and_Roosevelt_NFS',
        altText: 'Arapaho & Roosevelt National Forest',
    },
    {
        imagePreviewUrl: bonoImageSrc,
        imageDownloadUrl: 'Sonny_Bono_Salton_Sea_National_Wildlife_Refuge',
        altText: 'Sonny Bono Salton Sea National Wildlife Refuge',
    },
    {
        imagePreviewUrl: capitolReefImageSrc,
        imageDownloadUrl: 'Capitol_Reef_NP',
        altText: 'Capitol Reef National Park',
    },
    {
        imagePreviewUrl: coronadoImageSrc,
        imageDownloadUrl: 'Coronado_NF',
        altText: 'Coronado National Forest',
    },
    {
        imagePreviewUrl: craterLakeImageSrc,
        imageDownloadUrl: 'Crater_Lake_NP',
        altText: 'Crater Lake National Park',
    },
    {
        imagePreviewUrl: deschutesImageSrc,
        imageDownloadUrl: 'Deschutes_National_Forest',
        altText: 'Deschutes National Forest',
    },
    {
        imagePreviewUrl: haleakalaImageSrc,
        imageDownloadUrl: 'Haleakala_NP',
        altText: 'Haleakala National Park',
    },
    {
        imagePreviewUrl: havasuImageSrc,
        imageDownloadUrl: 'Havasu_NWR',
        altText: 'Havasu National Wildlife Refuge',
    },
    {
        imagePreviewUrl: jfkImageSrc,
        imageDownloadUrl: 'John_F_Kennedy_Park',
        altText: 'John F Kennedy Park',
    },
    {
        imagePreviewUrl: rainierImageSrc,
        imageDownloadUrl: 'Mount_Rainier_National_Park',
        altText: 'Mount Rainier National Park',
    },
    {
        imagePreviewUrl: staircaseImageSrc,
        imageDownloadUrl: 'Grand_Staircase_Escalante_National_Monument',
        altText: 'Grand Staircase Escalante National Monument',
    },
];

export default function MediaCenterPhotoDownloads() {
    return (
        <section className="nav-media-center-photo-downloads px-3 py-3 px-md-5 py-md-4">
            <FlexRow justifyContent="center">
                <FlexCol lg={10} xxl={8}>
                    <div className="nav-media-center-photo-downloads-container p-md-5 mt-1">
                        <FlexRow>
                            <FlexCol sm={12}>
                                <Heading
                                    headingLevel={2}
                                    appearance="h4"
                                    className="mb-2"
                                >
                                    Recreation.gov Virtual Backgrounds
                                </Heading>
                                <p className="mb-4">
                                    Bring an incredible outdoor scene right to your screen
                                    for meetings and get togethers. Checkout our selection
                                    of images of public lands and waters from the Share
                                    the Experience photo contest below, save your
                                    favorites, and add some outdoor fun to your virtual
                                    routine!
                                </p>
                            </FlexCol>
                        </FlexRow>

                        <FlexRow>
                            {photoItems.map((photoItem) => (
                                <FlexCol
                                    xs={6}
                                    md={4}
                                    xl={3}
                                    className="mb-3"
                                    key={photoItem.imagePreviewUrl}
                                >
                                    <a
                                        href={`https://cdn.recreation.gov/downloads/virtual_background_${photoItem.imageDownloadUrl}.jpg`}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="nav-media-center-photo-download-wrap"
                                        aria-label={`Download a virtual background of ${photoItem.altText}`}
                                    >
                                        <Icon iconName="file-download" />

                                        <FauxImage
                                            className="nav-media-center-photo-download-item"
                                            src={photoItem.imagePreviewUrl}
                                            altText={photoItem.altText}
                                            aspectRatio="5:3"
                                        />
                                    </a>
                                </FlexCol>
                            ))}
                        </FlexRow>
                    </div>
                </FlexCol>
            </FlexRow>
        </section>
    );
}

// cSpell:ignore Havasu, Escalante
