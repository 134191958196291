/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * A checkbox that enables the auto search
 */
export default class MapAutoSearchControl {
    constructor(onClickSearch) {
        this._handleCheckboxClick = this._handleCheckboxClick.bind(this);
        this._handleButtonClick = this._handleButtonClick.bind(this);
        this._onClickSearchCallback = onClickSearch;
        this._isActive = true;
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-search';

        this._item1 = document.createElement('div');
        this._item1.classList.add(
            'mapboxgl-ctrl-search__item',
            'mapboxgl-ctrl-search__item-1'
        );

        this._label = document.createElement('label');
        this._label.className = 'rec-label-checkbox';

        this._checkbox = document.createElement('input');
        this._checkbox.type = 'checkbox';
        this._checkbox.className = 'rec-input-hide';
        this._checkbox.checked = true;
        this._checkbox.setAttribute('data-ga-tracking-id', '053887960666');
        this._checkbox.setAttribute('data-ga-tracking-value', 'Unselected');

        this._checkbox.addEventListener('click', this._handleCheckboxClick);

        this._span = document.createElement('span');
        this._span.className = 'rec-input-checkbox';

        this._title = document.createTextNode('Search as I move the map');

        this._label.appendChild(this._checkbox);
        this._label.appendChild(this._span);
        this._label.appendChild(this._title);
        this._item1.appendChild(this._label);

        this._item2 = document.createElement('div');
        this._item2.classList.add(
            'mapboxgl-ctrl-search__item',
            'mapboxgl-ctrl-search__item-2'
        );
        this._item2.style.display = 'none';
        this._button = document.createElement('button');
        this._button.setAttribute('aria-label', 'Search this Area');
        this._button.setAttribute('type', 'button');
        this._button.addEventListener('click', this._handleButtonClick);
        this._buttonTitle = document.createTextNode('Search this Area');
        this._button.appendChild(this._buttonTitle);
        this._item2.appendChild(this._button);

        this._container.appendChild(this._item1);
        this._container.appendChild(this._item2);

        return this._container;
    }

    _handleCheckboxClick(e) {
        this._isActive = e.target.checked;
        this._checkbox.setAttribute(
            'data-ga-tracking-value',
            e.target.checked ? 'Unselected' : 'Selected' // Need to reverse the values here because GA captures the state of the checkbox on click, not what it is changed to.
        );
        this.handle();

        if (this._isActive) {
            this.hide();
        } else {
            this.show();
        }
    }

    _handleButtonClick() {
        if (this._onClickSearchCallback) {
            this._onClickSearchCallback();
        }
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    handle() {
        if (this._isActive && this._onClickSearchCallback) {
            this._onClickSearchCallback(true);
        }
    }

    show() {
        this._item2.style.display = 'block';
    }

    hide() {
        this._item2.style.display = 'none';
    }
}
