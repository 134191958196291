/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const DEFAULT_RESERVATION_WINDOW_VALUES = {
    reservationWindow: {
        value: 6,
        unit: 'months',
    },
    groupReservationWindow: {
        value: 12,
        unit: 'months',
    },
};

// Season Types
export const PEAK_SEASON = 'Peak';
export const OFF_PEAK_SEASON = 'Off Peak';
export const FCFS_SEASON = 'Walk In';
export const TRANSITION_SEASON = 'Transition';

export const SEASON_TYPE_DISPLAY = {
    [PEAK_SEASON]: 'Peak Season',
    [OFF_PEAK_SEASON]: 'Non-Peak Season',
    [FCFS_SEASON]: 'First-come, First-served',
    [TRANSITION_SEASON]: 'Transition Season',
};

export const FCFS_TOOLTIP_CONTENT =
    'This site is not available to reserve in advance online or by phone. You must physically arrive at the facility to see if the site is available. There is no guarantee this site will be available to purchase upon your arrival.';

export const SEASON_DESCRIPTION_TIP_CONTENT = {
    [PEAK_SEASON]: 'Typically the busiest time of the year. Please check availability.',
    [OFF_PEAK_SEASON]: 'May be less busy. Please check availability.',
    [FCFS_SEASON]: FCFS_TOOLTIP_CONTENT,
    [TRANSITION_SEASON]:
        'Nightly rates may change next season. Please check availability.',
};

export const CALENDAR_TOOLTIP_CONTENT = {
    [FCFS_SEASON]: FCFS_TOOLTIP_CONTENT,
};

export const ITINERARY_TYPE_FACILITY = 'ITINERARY';
export const ITINERARY_SINGLE_TYPE_FACILITY = 'ITINERARY_SINGLE';
export const STANDARD_TYPE_FACILITY = 'STANDARD';
export const RESERVATION_FEE = 8;

export const ANCHORAGE_SITES = ['273757'];

export const MAILING_ADDRESS = 'Mailing';
export const PHYSICAL_ADDRESS = 'Physical';

export const ADDRESS_TYPE_VALUES = {
    [MAILING_ADDRESS]: ['Default', MAILING_ADDRESS, 'mailing_address'],
    [PHYSICAL_ADDRESS]: [PHYSICAL_ADDRESS, 'physical_address'],
};

export const CAMPGROUND_CAMPSITE_MAP = 'CAMPGROUND_CAMPSITE_MAP';

export const MAX_RETRY_COUNT = 3;

export const GRID_SORT = 'gridSortObject';

// Business Rules
export const MAX_CONSECUTIVE_STAY_DEFAULT = 14;

export const AVAILABILITY_GRID = 'AVAILABILITY_GRID';
export const CAMPSITE_LIST = 'CAMPSITE_LIST';

export const LAST_VISITED_TAB = 'lastVisitedTab';

export const RULE_VALUE_PATH = 'value';

// Facility Stay Rules
export const MAX_RESERVATION_CHANGES_RULE_NAME = 'maxChanges'; // Maximum reservation changes
export const MAX_CONSECUTIVE_STAY_RULE_NAME = 'maxConsecutiveStay'; // Maximum consecutive stay
export const MAX_CONCURRENT_STAY_RULE_NAME = 'maxConcurrentStay'; // Maximum concurrent stay
export const MAX_STAY_RULE_NAME = 'maxStay'; // Maximum stay
export const MAX_TRANSACTION_RESERVATION_RULE_NAME = 'maxTransactionReservations'; // Maximum reservations per transaction
export const MAX_TRANSACTION_RESERVATION_DEFAULT = 4;
export const MAX_TOTAL_RESERVATION_RULE_NAME = 'maxSimultaneousReservations'; // Maximum total reservations
export const MAX_TOTAL_RESERVATION_DEFAULT = 20;
export const MIN_CONSECUTIVE_STAY_RULE_NAME = 'minConsecutiveStay'; // Minimum stay
export const MIN_WEEKEND_STAY_RULE_NAME = 'minWeekendStay'; // Two day weekend minimum
export const MIN_HOLIDAY_WEEKEND_RULE_NAME = 'minHolidayWeekendStay'; // Three day holiday weekend
export const IN_FLIGHT_MODIFICATION_ENABLED_RULE_NAME = 'allowMidStayModification'; // Public Visitor Self Service Modifications After Arrival Allowed Enabled Rule

// Special facility IDs
export const VOYAGEURS_FACILITY_ID = '249981';
export const OKEFENOKEE_FACILITY_ID = '250860';
