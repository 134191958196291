/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * HeroContainer.jsx
 * Created by Destin frasier on 05/10/21
 * Updated on 09/21/21
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';

import {
    SearchActions,
    SearchBarContainer,
    Heading,
    Icon,
    FlexRow,
    FlexCol,
    Tabs,
    TabPanel,
    DropdownMenu,
    Button,
    Radio,
    RadioGroup,
    MapHelper,
    Divider,
    Text,
    Badge,
    DatePicker,
    withLDConsumer,
    FeatureFlag,
    localToday,
} from 'sarsaparilla';
import { getGatewayUrl } from 'shared-ui/utils/getGatewayUrl';
import { HOMEPAGE_HERO_INVENTORY } from 'site-kit';

import { searchDate } from 'ui-search/src/utils/dateUtils';

import { CAMPSITE_ACCESSIBLE_FILTER as accessibilityFilter } from 'ui-search/src/constants';
import * as searchActions from 'ui-search/src/actions/search';

import { isWithinUS } from '../../utils/stateHelper';
import { PhotoInfoFlyout } from '../../components/homepage/hero/PhotoInfoFlyout';
import HeroAiSearch from '../../components/homepage/hero/HeroAiSearch';
import { SEARCH } from '../../constants/propTypes.js';
import AccessibleCheckbox from '../../components/sharedComponents/AccessibleCheckbox';
import FlexDates from '../../components/homepage/FlexDates/FlexDatesMain';
import ExploreAllQuickPromos from './ExploreAllQuickPromos';
import CampingLodgingCarousel from './heroCarousels/CampingLodgingCarousel';
import TicketsToursCarousel from './heroCarousels/TicketsToursCarousel';
import PermitsCarousel from './heroCarousels/PermitsCarousel';
import DayUseVenuesCarousel from './heroCarousels/DayUseVenueCarousel';
import MobileSearch from './MobileSearch';
import TourTimeFilter from './TourTimeFilter';

// Hero Image Info
const heroImages = [
    {
        heroBgImg: 'hero-01',
        locationName: 'Cherokee National Forest, TN',
        locationLink: getGatewayUrl('1048'),
        photoCredit: 'Donna Price (USFS Flickr)',
        altText: 'Wildflowers in bloom at Cherokee National Forest, TN',
    },
    {
        heroBgImg: 'hero-02',
        locationName: 'Carrizo Plain National Monument, CA',
        locationLink: getGatewayUrl('3112'),
        photoCredit: 'Bob Wick (BLM Flickr)',
        altText:
            'Wildflowers in bloom across the valley at Carrizo Plain National Monument, CA',
    },
    {
        heroBgImg: 'hero-03',
        locationName: 'Joshua Tree National Park, CA',
        locationLink: getGatewayUrl('2782'),
        photoCredit: 'Doreen Lawrence (Share the Experience)',
        altText: 'Wildflowers in bloom at Joshua Tree National Park, CA',
    },
    {
        heroBgImg: 'hero-04',
        locationName: 'Glacier National Park, MT',
        locationLink: getGatewayUrl('2725'),
        photoCredit: 'Jeremy Janus (Share the Experience)',
        altText:
            'Wildflowers in bloom across the valley within Glacier National Park, MT',
    },
    {
        heroBgImg: 'hero-05',
        locationName: 'Alpine North Loop, WY',
        locationLink:
            'https://www.recreation.gov/camping/campgrounds/232210?tab=campsites',
        photoCredit: 'Kelsey Bean (USFS Flickr)',
        altText: 'Wildflowers in bloom at Alpine North Loop, WY',
    },
];

const SEARCH_CAL_DATE_FORMAT = 'MM/DD/YYYY';

const TENT_FILTER = {
    key: 'campsite-equipment-tent',
    value: 'Tent',
    filters: [
        {
            attribute: 'campsite_equipment_name',
            filterValue: 'Tent',
        },
    ],
};

const RV_FILTER = {
    key: 'campsite-equipment-rv',
    value: 'RV/Motorhome',
    filters: [
        {
            attribute: 'campsite_equipment_name',
            filterValue: 'RV,RV/Motorhome',
        },
    ],
};

const CABIN_FILTER = {
    key: 'campsite-type-cabin',
    value: 'Cabin',
    filters: [
        {
            attribute: 'campsite_type',
            filterValue: 'CABIN ELECTRIC,CABIN NONELECTRIC',
        },
    ],
};

const LOOKOUT_FILTER = {
    key: 'campsite-type-lookout',
    value: 'Lookout',
    filters: [
        {
            attribute: 'campsite_type',
            filterValue: 'LOOKOUT',
        },
    ],
};

const YURTS_FILTER = {
    key: 'campsite-type-yurts',
    value: 'Yurts',
    filters: [
        {
            attribute: 'campsite_type',
            filterValue: 'YURT',
        },
    ],
};

const SEARCH_INVENTORY_TYPES = {
    CAMPING_TAB: ['campground', 'recarea'],
    TICKETS_TOURS_TAB: ['ticketfacility', 'timedentry'],
    PERMITS_TAB: ['permit'],
    DAY_USE_VENUES_TAB: ['dayuse', 'venuereservations'],
};
const ENABLED_SEARCH_ON_SELECT_TYPES = ['kb', 'cms', 'article'];

const tabPanelGACategory = 'Homepage';
const tabPanelGALabel = 'Hero Search';

export class HeroContainer extends React.Component {
    static propTypes = {
        search: SEARCH,
        dispatch: PropTypes.func.isRequired,
        submitSimpleSearch: PropTypes.func,
        flags: PropTypes.shape({
            availableThisWeekend: PropTypes.bool,
            useIaSearchParse: PropTypes.bool,
        }),
    };

    constructor(props) {
        super(props);
        let startDate = null;
        const flexDates = {};

        if (props.search.checkin_time) {
            startDate = searchDate(props.search.checkin_time);
            flexDates.startDate = searchDate(props.search.checkin_time);
        }
        let endDate = null;
        if (props.search.checkout_time) {
            endDate = searchDate(props.search.checkout_time);
            flexDates.endDate = searchDate(props.search.checkout_time);
        }
        if (props.search.avail_nights) {
            flexDates.duration = props.search.avail_nights;
        }
        if (props.search.flex_start_range) {
            flexDates.startRange = props.search.flex_start_range;
        }
        if (props.search.flex_end_range) {
            flexDates.endRange = props.search.flex_end_range;
        }
        if (props?.search?.avail_months) {
            flexDates.months = props?.search?.avail_months?.map((item) =>
                searchDate(item).utc().format('MM-YYYY')
            );
        }
        const activeSearchTabIndex = Number(
            window.sessionStorage.getItem('home-active-search-tab-index') || 0
        );

        const waysToStay = 'All';

        this.state = {
            photoFlyout: false,
            useMobile: false,
            searchPlaceholder:
                'Search for campsites, tours, and other recreation activities...',
            searchPlaceholderCamping: 'Search by location or campground name',
            startDate,
            endDate,
            activeSearchTabIndex,
            waysToStay,
            searchLoading: false,
            campingTabCarouselData: this.nearbyLocationsShape(),
            ticketsTabCarouselData: this.nearbyLocationsShape(),
            permitsTabCarouselData: this.nearbyLocationsShape(),
            dayUseVenuesTabCarouselData: this.nearbyLocationsShape(),
            flexDatesFilter: flexDates,
            geoIp: null,
            tourTimeFilter: {
                earlyMorning: false,
                morning: false,
                earlyAfternoon: false,
                afternoon: false,
                evening: false,
                lateNight: false,
            },
        };

        this.measurePage = throttle(this.measurePage.bind(this), 50);
        this.randomHeroImage = heroImages[Math.floor(Math.random() * heroImages.length)];
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(
            SearchActions.updateSearchCriterias({
                what: '',
                headerTextQuery: '',
            })
        );

        SearchActions.fetchUserGeoIp()
            .then((geoIp) => {
                this.setState({
                    geoIp,
                });
            })
            .catch(() => {
                // This code commented out because it is not valid
                // this.setState({
                //     [stateKey]: {
                //         ...stateObjectByKey,
                //         hasErrors: true,
                //     },
                // });
            });

        window.addEventListener('pageshow', this.pageShowListener);
        window.addEventListener('resize', this.measurePage);
        this.measurePage();
        window.addEventListener('keydown', this.handleKeyDown);
        dispatch(
            SearchActions.updateSearchCriterias({
                selectedSuggestion: null,
            })
        );
    }

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState) {
        if (this.state.geoIp !== prevState.geoIp) {
            this.handleGetAvailableThisWeekend();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measurePage);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('scroll', this.closePhotoCredit);
        window.removeEventListener('pageshow', this.pageShowListener);
    }

    nearbyLocationsShape = () => {
        return {
            locations: [],
            isLoading: true,
            hasErrors: false,
        };
    };

    pageShowListener = (event) => {
        if (event.persisted) {
            this.setState({ searchLoading: false });
        }
    };

    generateWeekendDates = (start, end) => {
        const today = new Date();
        const isSunday = today.getDay() === 0;
        let startDate = null;
        let endDate = null;
        if (isSunday) {
            startDate = searchDate().add({ days: start });
            endDate = searchDate().add({ days: end });
        } else {
            startDate = searchDate().startOf('week').add({ days: start });
            endDate = searchDate().startOf('week').add({ days: end });
        }

        if (startDate.isSame(searchDate(), 'day')) {
            startDate = searchDate().startOf('day');
        }
        if (endDate.isSame(searchDate(), 'day')) {
            endDate = searchDate().startOf('day');
        }
        return [startDate, endDate];
    };

    fetchNearbyLocations = (
        stateKey,
        inventoryTypes = [],
        weekendRange,
        radius = 300
    ) => {
        const { windowWidth, [stateKey]: stateObjectByKey } = this.state;
        let { searchAcrossUS } = process.env.HOMEPAGE_OPTIONS;
        let { bounds } = process.env.SEARCH;
        if (this.props?.flags?.availableThisWeekend) {
            searchAcrossUS = false;
        }
        if (!searchAcrossUS && !bounds) {
            // default to search across us for some inventory types
            searchAcrossUS =
                inventoryTypes.length > 0 &&
                (inventoryTypes[0] === 'permits' ||
                    inventoryTypes[0] === 'tours' ||
                    inventoryTypes[0] === 'tickettours' ||
                    inventoryTypes[0] === 'timedentry_tours');
        }

        let startDate = null;
        let endDate = null;

        if (weekendRange) {
            const dateFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
            const [start, end] = this.generateWeekendDates(...weekendRange);
            startDate = start.format(dateFormat);
            endDate = end.format(dateFormat);
        }

        const geoipResult = this.state.geoIp;
        const userIsWithinUS = isWithinUS(geoipResult.latitude, geoipResult.longitude);

        if (!userIsWithinUS && !bounds) {
            bounds = {
                lat_sw: 32.5341,
                lng_sw: -124.482,
                lat_ne: 42.0095,
                lng_ne: -114.1315,
            };
        }

        searchActions
            .fetchNearbyInventoryLocations(
                geoipResult.latitude,
                geoipResult.longitude,
                inventoryTypes,
                19,
                searchAcrossUS,
                'available',
                [],
                0,
                [],
                `${geoipResult.city}, ${geoipResult.region_name}`,
                bounds,
                startDate,
                endDate,
                true,
                radius
            )
            .then((result) => {
                if (
                    (!result.data.results && radius < 1200) ||
                    (result.data.results &&
                        result.data.results.length <= 4 &&
                        radius < 1200)
                ) {
                    this.fetchNearbyLocations(
                        stateKey,
                        inventoryTypes,
                        weekendRange,
                        radius * 2
                    );
                    return;
                }

                // If the width of the viewport is greater than 1123px show
                // 19 results so that the carousel is full.
                const amountOfCards =
                    windowWidth > 1123
                        ? result.data.results.slice(0, 19)
                        : result.data.results.slice(0, 17);

                this.setState({
                    [stateKey]: {
                        ...stateObjectByKey,
                        locations: amountOfCards,
                        isLoading: false,
                    },
                });
            })
            .catch(() => {
                this.setState({
                    [stateKey]: {
                        ...stateObjectByKey,
                        hasErrors: true,
                    },
                });
            });
    };

    fetchCampingNearbyLocations = () => {
        this.fetchNearbyLocations('campingTabCarouselData', ['camping'], [5, 7]);
    };

    fetchTicketsNearbyLocations = () => {
        this.fetchNearbyLocations(
            'ticketsTabCarouselData',
            ['tickettours', 'activitypass'],
            [6, 7]
        );
    };

    fetchPermitsNearbyLocations = () => {
        this.fetchNearbyLocations('permitsTabCarouselData', ['permits']);
    };

    fetchDayUseVenuesNearbyLocations = () => {
        this.fetchNearbyLocations(
            'dayUseVenuesTabCarouselData',
            ['dayuse', 'venuereservations'],
            [6, 7]
        );
    };

    onSingleDateChange = (date) => {
        this.setState({
            startDate: date,
            endDate: date,
        });
    };

    onTourTimeChange = (tourTimeFilter) => {
        this.setState({ tourTimeFilter });
    };

    openPhotoCredit = () => {
        document.addEventListener('scroll', this.closePhotoCredit);

        this.setState({
            photoFlyout: true,
        });
    };

    closePhotoCredit = () => {
        document.removeEventListener('scroll', this.closePhotoCredit);

        this.setState({
            photoFlyout: false,
        });
    };

    measurePage = () => {
        const { windowWidth: stateWindowWidth } = this.state;
        const windowWidth = window.innerWidth;
        const isMobile = windowWidth < 768;

        /**
         * As we are displaying a different search on mobile, we ensure to
         * restore desktop container to its original state
         */
        if (isMobile) {
            this.closePhotoCredit();
        }

        if (stateWindowWidth !== windowWidth) {
            this.setState({
                windowWidth,
            });
        }

        this.setState({ useMobile: isMobile });
    };

    submitSearch = (e) => {
        try {
            e.preventDefault();

            const { search } = this.props;

            this.setState({ searchLoading: true });

            this.runSearch({
                searchText: search.what,
                suggestion: search.selectedSuggestion,
            });
        } catch (err) {
            this.setState({ searchLoading: false });
        }
        return false;
    };

    parseFlexDatesParams = (months, duration) => {
        let monthsArray = [];
        if (months) {
            months.map((item) => {
                const av = searchDate(item)
                    .startOf('months')
                    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
                monthsArray = [...monthsArray, av];
                return item;
            });
        }
        return { avail_months: monthsArray, avail_nights: duration };
    };

    applyCampingUIFilters = () => {
        const { search, dispatch } = this.props;
        const { startDate, endDate, flexDatesFilter, waysToStay } = this.state;
        const isAccessibleChecked =
            search?.uiFilters?.campsiteAmenities?.hasOwnProperty(
                accessibilityFilter.key
            ) || false;

        dispatch(SearchActions.resetSearchFilters());

        let startDateStr = null;
        if (startDate) {
            startDateStr = searchDate(startDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = searchDate(endDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }

        if (flexDatesFilter?.startDate && flexDatesFilter.endDate) {
            startDateStr = searchDate(flexDatesFilter?.startDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
            endDateStr = searchDate(flexDatesFilter?.endDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let startRange = null;
        if (flexDatesFilter.startRange) {
            startRange = flexDatesFilter.startRange;
        }

        let endRange = null;
        if (flexDatesFilter.endRange) {
            endRange = flexDatesFilter.endRange;
        }
        const { avail_months, avail_nights } = this.parseFlexDatesParams(
            flexDatesFilter?.months,
            flexDatesFilter?.duration
        );

        if (avail_months && avail_nights) {
            startDateStr = '';
            endDateStr = '';
        }
        let avMonths = avail_months;
        let avNights = avail_nights;
        if (isEmpty(flexDatesFilter)) {
            startDateStr = '';
            endDateStr = '';
            avMonths = [];
            avNights = '';
            endRange = '';
            startRange = '';
        }
        dispatch(
            SearchActions.updateSearchCriterias({
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                flex_start_range: startRange,
                flex_end_range: endRange,
                avail_nights: avNights || '',
                avail_months: avMonths || [],
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.CAMPING_TAB,
        };

        const fgParams = get(search, 'fg', []);
        if (waysToStay === 'Tent Camping') {
            dispatch(SearchActions.updateSearchCriteria('fg', [...fgParams, 'tent']));
        }

        if (waysToStay === 'RV/Motorhome/Trailer Camping') {
            dispatch(SearchActions.updateSearchCriteria('fg', [...fgParams, 'rmt']));
        }

        if (waysToStay === 'Lodging (Cabins/Yurts/Lookouts)') {
            dispatch(SearchActions.updateSearchCriteria('fg', [...fgParams, 'lodging']));
        }

        if (isAccessibleChecked) {
            uiFilters.campsiteAmenities = {};
            uiFilters.campsiteAmenities[accessibilityFilter.key] = accessibilityFilter;
        }

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    applyTicketsUIFilters = () => {
        const { dispatch } = this.props;
        const { startDate, endDate } = this.state;

        dispatch(SearchActions.resetSearchFilters());

        let startDateStr = null;
        if (startDate) {
            startDateStr = searchDate(startDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = searchDate(endDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }

        dispatch(
            SearchActions.updateSearchCriterias({
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.TICKETS_TOURS_TAB,
        };

        uiFilters.tourTimes = {};

        const { tourTimeFilter } = this.state;

        if (tourTimeFilter.earlyMorning) {
            uiFilters.tourTimes['tour-times-early-morning'] = {
                key: 'tour-times-early-morning',
                value: 'Early Morning (Midnight - 7:59 AM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'early_morning',
                    },
                ],
            };
        }

        if (tourTimeFilter.morning) {
            uiFilters.tourTimes['tour-times-morning'] = {
                key: 'tour-times-morning',
                value: 'Morning (8 - 11:59 AM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'morning',
                    },
                ],
            };
        }

        if (tourTimeFilter.earlyAfternoon) {
            uiFilters.tourTimes['tour-times-early-afternoon'] = {
                key: 'tour-times-early-afternoon',
                value: 'Early Afternoon (12 - 2:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'early_afternoon',
                    },
                ],
            };
        }

        if (tourTimeFilter.afternoon) {
            uiFilters.tourTimes['tour-times-afternoon'] = {
                key: 'tour-times-afternoon',
                value: 'Afternoon (3 - 5:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'afternoon',
                    },
                ],
            };
        }

        if (tourTimeFilter.evening) {
            uiFilters.tourTimes['tour-times-evening'] = {
                key: 'tour-times-evening',
                value: 'Evening (6 - 8:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'evening',
                    },
                ],
            };
        }

        if (tourTimeFilter.lateNight) {
            uiFilters.tourTimes['tour-times-late-night'] = {
                key: 'tour-times-late-night',
                value: 'Late Night (9 - 11:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'late_night',
                    },
                ],
            };
        }

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    buildTourTimeFilter = () => {
        const tourTimeFilter = [];
        const ttFilter = this.state.tourTimeFilter;
        if (ttFilter) {
            if (ttFilter.earlyMorning) {
                tourTimeFilter.push('early_morning');
            }
            if (ttFilter.morning) {
                tourTimeFilter.push('morning');
            }
            if (ttFilter.earlyAfternoon) {
                tourTimeFilter.push('early_afternoon');
            }
            if (ttFilter.afternoon) {
                tourTimeFilter.push('afternoon');
            }
            if (ttFilter.evening) {
                tourTimeFilter.push('evening');
            }
            if (ttFilter.lateNight) {
                tourTimeFilter.push('late_night');
            }
        }
        return tourTimeFilter;
    };

    applyPermitsUIFilters = () => {
        const { dispatch } = this.props;

        dispatch(SearchActions.resetSearchFilters());

        dispatch(
            SearchActions.updateSearchCriterias({
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.PERMITS_TAB,
        };

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    applyDayUseVenuesUiFilters = () => {
        const { dispatch } = this.props;
        const { startDate, endDate } = this.state;

        dispatch(SearchActions.resetSearchFilters());

        let startDateStr = null;
        if (startDate) {
            startDateStr = searchDate(startDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = searchDate(endDate)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }

        dispatch(
            SearchActions.updateSearchCriterias({
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.DAY_USE_VENUES_TAB,
        };

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    getTabUIFilters = (tab) => {
        let filters;

        if (tab === 'Camping') {
            filters = this.applyCampingUIFilters();
        }

        if (tab === 'Tickets') {
            filters = this.applyTicketsUIFilters();
        }

        if (tab === 'Permits') {
            filters = this.applyPermitsUIFilters();
        }

        if (tab === 'Day Use / Venues') {
            filters = this.applyDayUseVenuesUiFilters();
        }

        return filters;
    };

    showMainSearchResults = (tab = '') => {
        const { dispatch, search } = this.props;
        try {
            const uiFilters = this.getTabUIFilters(tab);

            dispatch(
                searchActions.submitSearchFromNavigation(
                    search.what,
                    search.selectedSuggestion,
                    uiFilters
                )
            );
        } catch (err) {
            this.setState({ searchLoading: false });
        }
        return false;
    };

    submitCampingSearch = () => {
        const { search } = this.props;
        const { flexDatesFilter } = this.state;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };
            const uiFilters = this.applyCampingUIFilters();

            this.setState({ searchLoading: true });

            const { avail_months, avail_nights } = this.parseFlexDatesParams(
                flexDatesFilter?.months,
                flexDatesFilter?.duration
            );

            if (avail_nights && avail_months?.length) {
                suggestion.avail_nights = avail_nights;
                suggestion.avail_months = avail_months;
            } else {
                suggestion.checkin_time = flexDatesFilter.startDate;
                suggestion.checkout_time = flexDatesFilter.endDate;
                if (flexDatesFilter.startRange) {
                    suggestion.flex_start_range = flexDatesFilter.startRange;
                }
                if (flexDatesFilter.endRange) {
                    suggestion.flex_end_range = flexDatesFilter.endRange;
                }
            }

            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            this.showMainSearchResults('Camping');
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    runSearch = async ({ searchText, suggestion, searchHistory }) => {
        const { dispatch, search } = this.props;

        const isAccessibleChecked =
            search?.uiFilters?.campsiteAmenities?.hasOwnProperty(
                accessibilityFilter.key
            ) || false;

        const uiFilters = {};
        if (isAccessibleChecked) {
            uiFilters.campsiteAmenities = {
                ...(search?.uiFilters?.campsiteAmenities || {}),
            };
            uiFilters.inventoryTypes = { ...HOMEPAGE_HERO_INVENTORY.CAMPING_TAB };
        }

        try {
            await SearchActions.addSearchHistoryItem({
                searchHistory,
                rawSearchText: searchText,
                suggestion,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('error saving history', e);
        }

        dispatch(
            searchActions.submitSimpleSearch(
                searchText,
                null,
                suggestion,
                null,
                uiFilters,
                false,
                null,
                null,
                null,
                false,
                search.fg ?? []
            )
        );
    };

    handleGetAvailableThisWeekend = (value) => {
        const {
            activeSearchTabIndex,
            campingTabCarouselData,
            ticketsTabCarouselData,
            permitsTabCarouselData,
            dayUseVenuesTabCarouselData,
        } = this.state;

        const currentIndex = !value ? activeSearchTabIndex : value;

        if (
            currentIndex === 1 &&
            campingTabCarouselData?.locations?.length === 0 &&
            campingTabCarouselData?.isLoading
        ) {
            this.fetchCampingNearbyLocations();
        }
        if (
            currentIndex === 2 &&
            ticketsTabCarouselData?.locations?.length === 0 &&
            ticketsTabCarouselData?.isLoading
        ) {
            this.fetchTicketsNearbyLocations();
        }
        if (
            currentIndex === 3 &&
            permitsTabCarouselData?.locations?.length === 0 &&
            permitsTabCarouselData?.isLoading
        ) {
            this.fetchPermitsNearbyLocations();
        }
        if (
            currentIndex === 4 &&
            dayUseVenuesTabCarouselData?.locations?.length === 0 &&
            dayUseVenuesTabCarouselData?.isLoading
        ) {
            this.fetchDayUseVenuesNearbyLocations();
        }
    };

    handleSearchTabChange = (newIndex) => {
        this.handleGetAvailableThisWeekend(Number(`${newIndex}`));
        this.setState({ activeSearchTabIndex: Number(`${newIndex}`) });
        window.sessionStorage.setItem('home-active-search-tab-index', newIndex);
    };

    handleSearchFilterChange = (event) => {
        const value = event.target.value;
        this.setState({
            waysToStay: value,
        });
    };

    onWaysToStayApplyClicked = () => {};

    submitTicketsSearch = () => {
        const { search } = this.props;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };

            const uiFilters = this.applyTicketsUIFilters();

            this.setState({ searchLoading: true });

            suggestion.checkin_time = search.checkin_time;
            suggestion.checkout_time = search.checkout_time;
            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            const tourTimeFilter = this.buildTourTimeFilter();

            SearchActions.fetchInventoryAvailabilityResults(
                search.what,
                suggestion?.entity_id,
                suggestion?.entity_type,
                uiFilters,
                search.checkin_time,
                search.checkout_time,
                null,
                true,
                true,
                null,
                null,
                tourTimeFilter
            )
                .then((result) => {
                    // clear some camping item that causes filters to be reset
                    window.localStorage.removeItem('last-visited-campground');
                    let firstResult = null;
                    if (result.data.results && result.data.results.length > 0) {
                        firstResult = result.data.results[0];
                        if (
                            search.what?.toLowerCase().trim() ===
                                firstResult.name.toLowerCase() &&
                            firstResult.entity_type === 'ticketfacility'
                        ) {
                            const url = MapHelper.getTargetUri(firstResult);

                            window.location.assign(url);

                            return;
                        }
                    }
                    this.showMainSearchResults('Tickets');
                })
                .catch(() => {
                    this.showMainSearchResults('Tickets');
                });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    submitPermitsSearch = () => {
        const { search } = this.props;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };

            const uiFilters = this.applyPermitsUIFilters();

            this.setState({ searchLoading: true });

            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            SearchActions.fetchInventoryAvailabilityResults(
                search.what,
                suggestion?.entity_id,
                suggestion?.entity_type,
                uiFilters,
                search.checkin_time,
                search.checkout_time
            )
                .then((result) => {
                    // clear some camping item that causes filters to be reset
                    window.localStorage.removeItem('last-visited-campground');
                    let firstResult = null;
                    if (result.data.results && result.data.results.length > 0) {
                        firstResult = result.data.results[0];
                        if (
                            search.what?.toLowerCase().trim() ===
                                firstResult.name.toLowerCase() &&
                            firstResult.entity_type === 'permit'
                        ) {
                            const url = MapHelper.getTargetUri(firstResult);

                            window.location.assign(url);

                            return;
                        }
                    }
                    this.showMainSearchResults('Permits');
                })
                .catch(() => {
                    this.showMainSearchResults('Permits');
                });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    submitDayUseVenuesSearch = () => {
        const { search } = this.props;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };

            const uiFilters = this.applyDayUseVenuesUiFilters();

            this.setState({ searchLoading: true });

            suggestion.checkin_time = search.checkin_time;
            suggestion.checkout_time = search.checkout_time;
            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            SearchActions.fetchInventoryAvailabilityResults(
                search.what,
                suggestion?.entity_id,
                suggestion?.entity_type,
                uiFilters,
                search.checkin_time,
                search.checkout_time
            )
                .then((result) => {
                    // clear some camping item that causes filters to be reset
                    window.localStorage.removeItem('last-visited-campground');

                    let firstResult = null;

                    if (result.data.results && result.data.results.length > 0) {
                        firstResult = result.data.results[0];
                        if (
                            search.what?.toLowerCase().trim() ===
                                firstResult.name.toLowerCase() &&
                            (firstResult.campsite_type_of_use.includes('Day') ||
                                firstResult.campsite_type_of_use.includes('Overnight'))
                        ) {
                            const url = MapHelper.getTargetUri(firstResult);

                            window.location.assign(url);

                            return;
                        }
                    }
                    this.showMainSearchResults('Day Use / Venues');
                })
                .catch(() => {
                    this.showMainSearchResults('Day Use / Venues');
                });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    shouldRenderAccessibleCamping = () => {
        const { activeSearchTabIndex } = this.state;

        if (activeSearchTabIndex === 0 || activeSearchTabIndex === 1) {
            return <AccessibleCheckbox iconSize="sm" />;
        }

        return null;
    };

    getClickTagActionComplement = () => {
        const { search } = this.props;

        if (search.what) {
            return ` - ${search.what}`;
        }

        return '';
    };

    buildGATrackingObject = (suggestion, tab, isNearMe = false) => {
        let clickTagAction = '';
        const clickTagCategory = 'Homepage';
        const clickTagLabel = 'Hero';
        if (suggestion) {
            clickTagAction = `${tab}${
                suggestion.last_used ? ' History' : ''
            } Suggestion - ${suggestion.name}`;
        }

        if (isNearMe) {
            clickTagAction = `${tab} - Select Search Suggestion Near Me`;
        }

        return {
            clickTagCategory,
            clickTagAction,
            clickTagLabel,
        };
    };

    onFlexFiltersChange = (flexDatesRaw) => {
        const params = { ...flexDatesRaw };

        if (isEmpty(params)) {
            this.setState({ flexDatesFilter: {} });
            return;
        }

        if (params?.duration && params?.months) {
            params.startDate = null;
            params.endDate = null;
        } else {
            params.duration = null;
            params.months = null;
        }
        this.setState({
            flexDatesFilter: params,
        });
    };

    getCampingWaysToStayList = () => {
        return [
            {
                id: `homepage-search-options-all`,
                className: 'mb-2',
                label: 'All',
                value: 'All',
            },
            {
                id: `homepage-search-options-rv-camping`,
                className: 'mb-2',
                label: 'RV/Motorhome/Trailer Camping',
                value: 'RV/Motorhome/Trailer Camping',
            },
            {
                id: `homepage-search-options-tent-camping`,
                className: 'mb-2',
                label: 'Tent Camping',
                value: 'Tent Camping',
            },
            {
                id: `homepage-search-options-cabin-yurts`,
                label: 'Lodging (Cabins/Yurts/Lookouts)',
                value: 'Lodging (Cabins/Yurts/Lookouts)',
            },
        ];
    };

    render() {
        const {
            flexDatesFilter,
            startDate,
            useMobile,
            waysToStay,
            windowWidth,
            searchLoading,
            photoFlyout,
            activeSearchTabIndex,
            campingTabCarouselData,
            ticketsTabCarouselData,
            permitsTabCarouselData,
            dayUseVenuesTabCarouselData,
            searchPlaceholder: stateSearchPlaceholder,
            searchPlaceholderCamping: stateSearchPlaceholderCamping,
        } = this.state;
        const { submitSimpleSearch, flags } = this.props;

        // Hero Image Info
        const heroBgImg = this.randomHeroImage.heroBgImg;
        const locationName = this.randomHeroImage.locationName;
        const locationLink = this.randomHeroImage.locationLink;
        const photoCredit = this.randomHeroImage.photoCredit;
        const altText = this.randomHeroImage.altText;

        let searchPlaceholder = stateSearchPlaceholder;
        const searchPlaceholderCamping = stateSearchPlaceholderCamping;

        const heroImageSeasonYear = 'spring-25';
        const imageSpecs = [
            {
                size: 'lg',
                width: '2000w',
            },
            {
                size: 'md',
                width: '1440w',
            },
            {
                size: 'sm',
                width: '768w',
            },
            {
                size: 'xs',
                width: '375w',
            },
        ];

        // setup the hero image(s)
        const pictureSource = (imageFormat) => {
            const srcImage = () =>
                imageSpecs.map((imageSpec) => {
                    return `${process.env.CDN}/img/homepage/hero-section/${heroImageSeasonYear}-${heroBgImg}-${imageSpec.size}.${imageFormat} ${imageSpec.width}`;
                });

            return imageFormat === 'jpg' ? (
                <img
                    alt={altText}
                    src={`${process.env.CDN}/img/homepage/hero-section/${heroImageSeasonYear}-${heroBgImg}-md.${imageFormat}`}
                    srcSet={`${srcImage()}`}
                />
            ) : (
                <source
                    type={`image/${imageFormat}`}
                    sizes="100vw"
                    srcSet={`${srcImage()}`}
                />
            );
        };

        if (useMobile) {
            searchPlaceholder = 'Search for camping, tours and more...';
        }

        const defaultDropdownText = 'Ways to Stay';
        const waysToStayFilterLabel =
            waysToStay === 'All' ? (
                <>
                    <Icon iconName="tent" />
                    {defaultDropdownText}
                </>
            ) : (
                <>
                    <Icon iconName="tent" />
                    {waysToStay}
                </>
            );

        const navSearchCarouselItemsWidth = 208;

        if (windowWidth < 768) {
            return (
                <MobileSearch
                    isLoading={searchLoading}
                    submitSearch={this.submitSearch}
                    runSearch={this.runSearch}
                    pictureSource={pictureSource}
                />
            );
        }

        const viewMoreBtn = ({ uiFilters, key }) => {
            const goToResults = (e) => {
                e.preventDefault();

                const { trackRunSearch } = SearchActions;
                const { dispatch } = this.props;
                const gaEvent = {
                    category: 'Homepage',
                    action: `View all ${key} carousel link`,
                    label: 'Hero',
                };

                trackRunSearch(gaEvent, () => {
                    let { searchAcrossUS } = process.env.HOMEPAGE_OPTIONS;
                    let { bounds } = process.env.SEARCH;
                    if (!searchAcrossUS && !bounds) {
                        // default to search across us for some inventory types
                        searchAcrossUS = key === 'permits' || key === 'tours';
                    }
                    let dates = null;
                    if (this.props?.flags?.availableThisWeekend) {
                        const range = key === 'camping' ? [5, 7] : [6, 7];
                        const [start, end] = this.generateWeekendDates(...range);
                        dates = {
                            startDate: start,
                            endDate: end,
                        };
                    }
                    const geoipResult = this.state.geoIp;
                    const userIsWithinUS = isWithinUS(
                        geoipResult.latitude,
                        geoipResult.longitude
                    );

                    if (!userIsWithinUS && !bounds) {
                        bounds = {
                            lat_sw: 32.5341,
                            lng_sw: -124.482,
                            lat_ne: 42.0095,
                            lng_ne: -114.1315,
                        };
                    }
                    if ((key === 'tours' || key === 'permits') && !bounds) {
                        bounds = {
                            lng_sw: -167.2815442995964,
                            lat_sw: 3.6722362889127,
                            lng_ne: -51.637969429829155,
                            lat_ne: 60.77726184625004,
                        };
                    }
                    dispatch(
                        searchActions.submitSimpleSearch(
                            '',
                            [],
                            null,
                            null,
                            uiFilters,
                            searchAcrossUS,
                            bounds,
                            'available',
                            dates,
                            this.props?.flags?.availableThisWeekend
                        )
                    );
                });
            };
            return (
                <Button
                    appearance="link"
                    iconAfterElement={<Icon iconName="arrow-forward" />}
                    key={`${key}Container`}
                    onClick={goToResults}
                >
                    View More
                </Button>
            );
        };

        const allowedInventoryTypes = process.env.ALLOWED_INVENTORY_TYPES;
        const [campingStartDate, campingEndDate] = this.generateWeekendDates(5, 7);
        const [venuesStartDate, venuesEndDate] = this.generateWeekendDates(6, 7);
        const [ticketStartDate, ticketEndDate] = this.generateWeekendDates(6, 7);
        const enableAvailableThisWeekend = flags?.availableThisWeekend;
        const waysToStayList = this.getCampingWaysToStayList();

        return (
            <section className="nav-hero-wrap-new">
                <div className="nav-hero-content-wrap">
                    <PhotoInfoFlyout
                        isOpen={photoFlyout}
                        open={this.openPhotoCredit}
                        close={this.closePhotoCredit}
                    >
                        <p>
                            <strong>Photo Location:</strong>{' '}
                            <Button
                                appearance="link"
                                isUnstyled
                                href={`${locationLink}`}
                                aria-label={`Visit ${locationName}`}
                                clickTagCategory="Homepage"
                                clickTagAction={`Image Location Link to - ${locationName}`}
                                clickTagLabel="Hero"
                            >
                                {locationName}
                            </Button>
                            {photoCredit && (
                                <>
                                    {' '}
                                    | <strong>Photo Credit:</strong> {photoCredit}
                                </>
                            )}
                        </p>
                    </PhotoInfoFlyout>

                    <FlexRow hasGutters={false}>
                        <FlexCol>
                            <div
                                className={cx('nav-hero-search-section-wrap-new', {
                                    'photo-fly-out': photoFlyout,
                                })}
                            >
                                {photoFlyout && (
                                    <div
                                        className="photo-fly-out-layer"
                                        onClick={this.closePhotoCredit}
                                        aria-hidden="true"
                                    />
                                )}
                                <Tabs
                                    stretchTabsToFit={false}
                                    activeIndex={activeSearchTabIndex}
                                    onChange={this.handleSearchTabChange}
                                    tabsAccessoryComponent={this.shouldRenderAccessibleCamping()}
                                >
                                    <TabPanel
                                        label="Explore All"
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Explore All - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <div className="form-item-wrap">
                                                <SearchBarContainer
                                                    buildGATrackingObject={(
                                                        suggestion,
                                                        isNearMe
                                                    ) =>
                                                        this.buildGATrackingObject(
                                                            suggestion,
                                                            'Explore All',
                                                            isNearMe
                                                        )
                                                    }
                                                    id="hero-search-input"
                                                    key="hero-search-input"
                                                    size="lg"
                                                    placeholder={searchPlaceholder}
                                                    onRunSearch={this.runSearch}
                                                    defaultEventCategory="Homepage"
                                                />
                                            </div>

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Explore All Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                onClick={this.submitSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <ExploreAllQuickPromos
                                                search={submitSimpleSearch}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label="Camping & Lodging"
                                        isHidden={!allowedInventoryTypes?.camping}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Camping & Lodging - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <div className="form-item-wrap">
                                                <SearchBarContainer
                                                    size="lg"
                                                    buildGATrackingObject={(
                                                        suggestion,
                                                        isNearMe
                                                    ) =>
                                                        this.buildGATrackingObject(
                                                            suggestion,
                                                            'Camping & Lodging',
                                                            isNearMe
                                                        )
                                                    }
                                                    placeholder={searchPlaceholderCamping}
                                                    onRunSearch={this.submitCampingSearch}
                                                    id="hero-camping-search-input"
                                                    key="hero-camping-search-input"
                                                    inventoryTypes={
                                                        SEARCH_INVENTORY_TYPES.CAMPING_TAB
                                                    }
                                                    searchOnSelectTypes={
                                                        ENABLED_SEARCH_ON_SELECT_TYPES
                                                    }
                                                    disableSearchOnSelect
                                                    defaultEventCategory="Homepage"
                                                    geocoder
                                                />
                                            </div>

                                            <DropdownMenu
                                                id="homepage-search-options"
                                                label={waysToStayFilterLabel}
                                                onApply={this.onWaysToStayApplyClicked}
                                                className={
                                                    waysToStayFilterLabel ===
                                                    defaultDropdownText
                                                        ? ''
                                                        : 'homepage-active-dropdown-text'
                                                }
                                            >
                                                <div style={{ minWidth: '200px' }}>
                                                    <RadioGroup
                                                        name="homepage-search-camping-options"
                                                        value={waysToStay}
                                                        onChange={
                                                            this.handleSearchFilterChange
                                                        }
                                                    >
                                                        {waysToStayList.map((item) => (
                                                            <Radio
                                                                key={item.id}
                                                                id={item.id}
                                                                className={item.className}
                                                                label={item.label}
                                                                value={item.value}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </div>
                                            </DropdownMenu>

                                            <div className="nav-search-flex-dates">
                                                <FlexDates
                                                    monthsLimit={4}
                                                    onFiltersChange={
                                                        this.onFlexFiltersChange
                                                    }
                                                    filters={flexDatesFilter}
                                                    showClear
                                                />
                                            </div>

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Camping & Lodging Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                onClick={this.submitCampingSearch}
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    {!enableAvailableThisWeekend && (
                                                        <Heading
                                                            headingLevel={2}
                                                            appearance="h6"
                                                        >
                                                            Camping Locations
                                                        </Heading>
                                                    )}
                                                    {enableAvailableThisWeekend && (
                                                        <div>
                                                            <Heading
                                                                headingLevel={2}
                                                                appearance="h6"
                                                            >
                                                                Available This Weekend
                                                            </Heading>
                                                            <Text className="py-1">
                                                                Check In:{' '}
                                                                {campingStartDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {campingStartDate.format(
                                                                    'MMMM D'
                                                                )}
                                                                , Check Out:{' '}
                                                                {campingEndDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {campingEndDate.format(
                                                                    'MMMM D'
                                                                )}
                                                            </Text>
                                                        </div>
                                                    )}

                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.CAMPING_TAB,
                                                        },
                                                        inventoryDisplayName:
                                                            'Campgrounds',
                                                        key: 'camping',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <CampingLodgingCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={campingTabCarouselData}
                                                enableAvailableThisWeekend={
                                                    enableAvailableThisWeekend
                                                }
                                                dates={{
                                                    startDate: campingStartDate,
                                                    endDate: campingEndDate,
                                                }}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label={
                                            <FeatureFlag
                                                flag="isActivitiesTab"
                                                fallback="Tickets & Tours"
                                            >
                                                Activities & Tickets
                                            </FeatureFlag>
                                        }
                                        isHidden={!allowedInventoryTypes?.tickets}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Tickets & Tours - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <div className="form-item-wrap">
                                                <SearchBarContainer
                                                    size="lg"
                                                    buildGATrackingObject={(
                                                        suggestion,
                                                        isNearMe
                                                    ) =>
                                                        this.buildGATrackingObject(
                                                            suggestion,
                                                            'Tickets & Tours',
                                                            isNearMe
                                                        )
                                                    }
                                                    placeholder="Search by location or facility name"
                                                    onRunSearch={this.submitTicketsSearch}
                                                    id="hero-tickets-search-input"
                                                    key="hero-tickets-search-input"
                                                    inventoryTypes={
                                                        SEARCH_INVENTORY_TYPES.TICKETS_TOURS_TAB
                                                    }
                                                    searchOnSelectTypes={
                                                        ENABLED_SEARCH_ON_SELECT_TYPES
                                                    }
                                                    defaultEventCategory="Homepage"
                                                    disableSearchOnSelect
                                                />
                                            </div>

                                            <TourTimeFilter
                                                onTourTimeChange={this.onTourTimeChange}
                                            />

                                            <DatePicker
                                                className="hero-date-picker"
                                                size="lg"
                                                label="Tickets & Tours date picker"
                                                isLabelVisible={false}
                                                minValue={localToday}
                                                value={startDate}
                                                onChange={this.onSingleDateChange}
                                            />

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Tickets & Tours Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                                onClick={this.submitTicketsSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    {!enableAvailableThisWeekend && (
                                                        <Heading
                                                            headingLevel={2}
                                                            appearance="h6"
                                                        >
                                                            Tickets & Tours Locations
                                                        </Heading>
                                                    )}

                                                    {enableAvailableThisWeekend && (
                                                        <div>
                                                            <Heading
                                                                headingLevel={2}
                                                                appearance="h6"
                                                            >
                                                                Available This Weekend
                                                            </Heading>
                                                            <Text className="py-1">
                                                                Options for{' '}
                                                                {ticketStartDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {ticketStartDate.format(
                                                                    'MMMM D'
                                                                )}{' '}
                                                                or{' '}
                                                                {ticketEndDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {ticketEndDate.format(
                                                                    'MMMM D'
                                                                )}
                                                            </Text>
                                                        </div>
                                                    )}

                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.TICKETS_TOURS_TAB,
                                                        },
                                                        inventoryDisplayName:
                                                            'Tickets & Tours',
                                                        key: 'tours',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <TicketsToursCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={ticketsTabCarouselData}
                                                enableAvailableThisWeekend={
                                                    enableAvailableThisWeekend
                                                }
                                                dates={{
                                                    startDate: ticketStartDate,
                                                    endDate: ticketEndDate,
                                                }}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label="Permits"
                                        isHidden={!allowedInventoryTypes?.permits}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Permits - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <div className="form-item-wrap">
                                                <SearchBarContainer
                                                    size="lg"
                                                    buildGATrackingObject={(
                                                        suggestion,
                                                        isNearMe
                                                    ) =>
                                                        this.buildGATrackingObject(
                                                            suggestion,
                                                            'Permits',
                                                            isNearMe
                                                        )
                                                    }
                                                    placeholder="Search by location or facility name"
                                                    onRunSearch={this.submitPermitsSearch}
                                                    id="hero-permits-input"
                                                    key="hero-permits-input"
                                                    inventoryTypes={
                                                        SEARCH_INVENTORY_TYPES.PERMITS_TAB
                                                    }
                                                    defaultEventCategory="Homepage"
                                                />
                                            </div>

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Permits Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                                onClick={this.submitPermitsSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    <Heading
                                                        headingLevel={2}
                                                        appearance="h6"
                                                    >
                                                        Permit Locations
                                                    </Heading>

                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.PERMITS_TAB,
                                                        },
                                                        inventoryDisplayName:
                                                            'Permit Locations',
                                                        key: 'permits',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <PermitsCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={permitsTabCarouselData}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label="Day Use / Venues"
                                        isHidden={!allowedInventoryTypes?.venues}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Day Use / Venues - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <div className="form-item-wrap">
                                                <SearchBarContainer
                                                    size="lg"
                                                    buildGATrackingObject={(
                                                        suggestion,
                                                        isNearMe
                                                    ) =>
                                                        this.buildGATrackingObject(
                                                            suggestion,
                                                            'Day Use / Venues',
                                                            isNearMe
                                                        )
                                                    }
                                                    placeholder="Search by location or facility name"
                                                    onRunSearch={
                                                        this.submitDayUseVenuesSearch
                                                    }
                                                    id="hero-dayuse-venues-input"
                                                    key="hero-dayuse-venues-input"
                                                    inventoryTypes={
                                                        SEARCH_INVENTORY_TYPES.DAY_USE_VENUES_TAB
                                                    }
                                                    searchOnSelectTypes={
                                                        ENABLED_SEARCH_ON_SELECT_TYPES
                                                    }
                                                    defaultEventCategory="Homepage"
                                                    disableSearchOnSelect
                                                />
                                            </div>

                                            <DatePicker
                                                label="Day Use / Venues date picker"
                                                className="hero-date-picker"
                                                value={startDate}
                                                size="lg"
                                                isLabelVisible={false}
                                                minValue={localToday}
                                                onChange={this.onSingleDateChange}
                                            />

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Day Use / Venues Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                                onClick={this.submitDayUseVenuesSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    {!enableAvailableThisWeekend && (
                                                        <Heading
                                                            headingLevel={2}
                                                            appearance="h6"
                                                        >
                                                            Day Use / Venues Locations
                                                        </Heading>
                                                    )}
                                                    {enableAvailableThisWeekend && (
                                                        <div>
                                                            <Heading
                                                                headingLevel={2}
                                                                appearance="h6"
                                                            >
                                                                Available This Weekend
                                                            </Heading>
                                                            <Text className="py-1">
                                                                Options for{' '}
                                                                {venuesStartDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {venuesStartDate.format(
                                                                    'MMMM D'
                                                                )}{' '}
                                                                or{' '}
                                                                {venuesEndDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {venuesEndDate.format(
                                                                    'MMMM D'
                                                                )}
                                                            </Text>
                                                        </div>
                                                    )}
                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.DAY_USE_VENUES_TAB,
                                                        },
                                                        inventoryName:
                                                            'dayuse&inventory_type=venuereservations',
                                                        inventoryDisplayName:
                                                            'Day Use / Venues',
                                                        key: 'dayUseVenues',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <DayUseVenuesCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={dayUseVenuesTabCarouselData}
                                                enableAvailableThisWeekend={
                                                    enableAvailableThisWeekend
                                                }
                                                dates={{
                                                    startDate: venuesStartDate,
                                                    endDate: venuesEndDate,
                                                }}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel
                                        label={
                                            <FlexRow>
                                                <FlexCol>
                                                    AI Search{' '}
                                                    <Badge
                                                        className="ml-half"
                                                        appearance="info"
                                                    >
                                                        Beta
                                                    </Badge>
                                                </FlexCol>
                                            </FlexRow>
                                        }
                                        isHidden={!this.props.flags?.useIaSearchParse}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction=" AI Search - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <HeroAiSearch />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </FlexCol>
                    </FlexRow>
                </div>

                <picture>
                    {/* Show the hero image as an .avif */}
                    {pictureSource('avif')}

                    {/* If the browser can't handle an .avif, try a .webP */}
                    {pictureSource('webp')}

                    {/* If the browser can't handle an .avif or .webP, go with .jpg */}
                    {pictureSource('jpg')}
                </picture>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
    };
};

export default withLDConsumer()(connect(mapStateToProps)(HeroContainer));

// cSpell:ignore Carrizo, Chah, Koustav, Maity, Eldorado, Rayna, Nickoson, Shandra, Mondragon, Asmussen, Lathrop, Cimarron, Fambrini, Midewin, Tallgrass, USFS, ticketfacility, dayuse, RECAREA, checkin, geoip, venuereservations, Criterias, timedentry, ACTIVITYPASS, Gifford, Pinchot, tickettours, destin, frasier, smilanic, schermer, Flickr
